

import SaveButton from "../save-button.vue";
import iriPreparation from "../../mixins/iri-preparation";
import {STATUS_APPROVED} from "../../mixins/cik-status";
import CIKTable from "./cik-table.vue";

export default {
    name: "CompanyCIKsModal",
    components: {SaveButton, CIKTable},
    mixins: [iriPreparation],
    props: {
        id: {
            type: String,
            default: "companyCIKsModal"
        },
        company: {
            type: Object,
            required: true,
        }
    },
    data() {
        return {
            cikToAdd: null,
            loading: false,
            tableKey: 'cikTableKey',
            cikNumber: null,
        }
    },
    watch: {},
    mounted() {
        this.$refs.modal.addEventListener('hidden.bs.modal', () => {
            this.$emit('closed', this.item)
        })
    },
    methods: {
        addCIK() {
            this.loading = true;
            this.$axios.post(`/api/ciks`, {
                company: '/api/companies/' + this.company.id,
                cik: this.cikToAdd.cik,
                name: this.cikToAdd.name,
                status: STATUS_APPROVED
            })
                .then(() => {
                    this.tableKey = this.generateUUID();
                    this.cikToAdd = null;
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        addCIKNumber() {
            this.loading = true;
            this.$axios.post(`/api/ciks`, {
                company: '/api/companies/' + this.company.id,
                cik: this.cikNumber,
                status: STATUS_APPROVED
            })
                .then(() => {
                    this.tableKey = this.generateUUID();
                    this.cikNumber = null;
                })
                .finally(() => {
                    this.loading = false;
                });
        }
    }
}
