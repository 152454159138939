

import moment from "moment";
import fileDownload from "../../mixins/file-download";
import LoadingSpinner from "../loading-spinner";

const DATE_FORMAT = "YYYY-MM-DD";

export default {
    name: "InvolvementReportModal",
    components: {
        LoadingSpinner,
    },
    mixins: [
        fileDownload,
    ],
    props: {
        company: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            listAllParticipants: true,
            endDate: null,
            generationIsInProgress: false,
            startDate: null,
        }
    },
    computed: {
        canGenerate() {
            if (this.generationIsInProgress) {
                return false;
            }
            if (this.startDate === null) {
                return false;
            }
            if (this.endDate === null) {
                return false;
            }

            return true;
        },
    },
    methods: {
        generateReport(fileType) {
            this.generationIsInProgress = true;
            this.$axios.post('/api/companies/involvement_report', {
                companyId: this.company.id,
                listAllParticipants: this.listAllParticipants,
                endDate: this.endDate,
                startDate: this.startDate,
                fileType,
            }, {
                responseType: 'blob'
            }).then((response) => {
                this.downloadBlob(response.data, `${this.company.name} Involvement Report.${fileType}`)
            }).finally(() => {
                this.generationIsInProgress = false;
            });
        },
        setPeriod(years) {
            if (this.endDate === null) {
                this.endDate = moment().add(3, 'months').format(DATE_FORMAT);
            }
            this.startDate = moment(this.endDate).subtract(years, 'years').format(DATE_FORMAT);
        },
    }
}
