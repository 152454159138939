

import fileDownload from "../../mixins/file-download";

export default {
    name: "InvestorsCard",
    mixins: [fileDownload],
    props: {
        company: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            investorsByRound: [],
            config: {
                columns: [
                    {
                        headline: "Round",
                        sort: true
                    },
                    {
                        headline: "Volume",
                        sort: true
                    },
                    {
                        headline: "Date",
                        sort: true,
                    },
                    {
                        headline: "Investors",
                        sticky: true,
                        hideHeadlineBreakpoint: "all",
                    },
                ],
                pagination: 5,
            },
            sortOrder: [],
            loadingInvestors: true,
        }
    },
    computed: {
        rows() {
            return this.investorsByRound.map(round => {
                return [
                    {
                        text: round.round,
                    },
                    {
                        text: round.volume,
                    },
                    {
                        text: this.formatDate(round.date),
                        sortValue: (new Date(round.date)).valueOf(),
                    },
                    {
                        type: "investors",
                        investors: round.investors,
                    },
                ]
            });
        }
    },
    mounted() {
        this.loadInvestors();
    },
    methods: {
        loadInvestors() {
            this.loadingInvestors = true;
            this.$axios.get('/api/companies/' + this.company.id + '/investors')
                .then(async (response) => {
                    this.investors = response.data.investors;
                    this.investorsByRound = response.data.investorsByRound;
                    await this.$nextTick();
                    this.sortOrder = [{
                        index: 2,
                        asc: false,
                    }];
                })
                .finally(() => {
                    this.loadingInvestors = false;
                });
        },
        downloadCallLog() {
            this.saveQueryAndExport({}, '/api/companies/' + this.company.id.toString() + '/investors_export');
        }
    }
}
