
import modals from "../../mixins/modals";
import EditLinkModal from "./edit-link-modal.vue";

export default {
    name: "LinksCard",
    components: {EditLinkModal},
    mixins: [modals],
    props: {
        companyId: {
            type: Number,
            required: true
        },
    },
    data() {
        return {
            links: null,
            linkToEdit: null,
            isNew: true,
            modal: null,
            config: {
                columns: [
                    { },
                    {
                        headline: "Actions",
                        hideHeadlineBreakpoint: "all",
                        align: "end",
                        ignoreEmpty: true,
                    },
                ],
                pagination: 5,
            },
            editLinkModalKey: 'editLinkModal',
        }
    },
    computed: {
        rows() {
            return Array.apply([], this.links).map((ca) => {
                return [
                    {
                        type: "title",
                        text: ca.title,
                        url: ca.url,
                        id: ca.id,
                    },
                    {
                        type: "actions",
                        id: ca.id,
                    },
                ];
            });
        },
    },
    created() {
        this.reloadLinks();
    },
    methods: {
        addLink() {
            this.isNew = true;
            this.linkToEdit = null;
            this.showEditForm();
        },
        editLink(id) {
            for (const i in this.links) {
                if(this.links[i].id === id) {
                    this.linkToEdit = this.links[i];
                }
            }
            this.isNew = false;
            this.showEditForm();
        },
        async showEditForm() {
            this.editLinkModalKey = this.generateUUID();
            await this.$nextTick();
            this.modal = this.openModal(this.$refs.editLinkModal);
        },
        reloadLinks() {
            this.$axios.get("/api/company_links?company=" + this.companyId).then((response) => {
                this.links = response.data['hydra:member'];
            });
        },
        linkUpdated() {
            this.$emit('company-updated');
            this.closeModal(this.modal);
            this.reloadLinks();
        }
    }
}
