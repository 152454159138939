
import ContactName from "../contacts/contact-name";
import emailPopoverActionsMixin from "~/mixins/email-popover-actions";

export default {
    name: "KeyContactsCard",
    components: {ContactName},
    mixins: [emailPopoverActionsMixin],
    props: {
        keyContacts: {
            type: Array,
            required: true
        }
    },
    data(){
        return {
            config: {
                columns: [
                    {
                        headline: "Name",
                    },
                    {
                        headline: "Job Title",
                    }
                ],
            },
        }
    },
    computed: {
        rows() {
            return this.keyContacts.map(contact => {
                return [
                    {
                        type: "contact",
                        contact
                    },
                    {
                        text: contact.jobTitle
                    },
                ]
            });
        },

    }
}
