
export default {
    name: "KnownPeopleModal",
    props: {
        knownPeople: {
            type: Array,
            required: true
        },
        id: {
            type: Number,
            required: true
        },
        fromContactId: {
            type: Array,
            required: true
        },
        continuingKnownPeople: {
            type: Array,
            required: true
        },
        continuingFromContactId: {
            type: Array,
            required: true
        },
    },
    data() {
        return {
            orderedRelationships: [],
            fromContacts: [],
            orderedContinuingRelationships: [],
            continuingFromContacts: [],
        }
    },

    mounted() {
        this.orderRelationships();
    },
    methods: {
        orderRelationships() {
            this.knownPeople.forEach((rel) => {
                if (!this.fromContacts[rel.fromContact.id]) {
                    this.fromContacts[rel.fromContact.id] = rel.fromContact
                }
            })
            this.fromContactId.forEach((id) => {
                this.orderedRelationships[id] = this.knownPeople.filter(r => r.fromContact.id === id).map(function (r) {
                    return r.toContact
                })
            }
            )
            this.fromContacts = this.fromContacts.filter((n) => n);

            // this part handles the previous employee (continued) and the peoples who know previous employee of this company
            this.continuingKnownPeople.forEach((rel) => {
                if (!this.continuingFromContacts[rel.fromContact.id]) {
                    this.continuingFromContacts[rel.fromContact.id] = rel.fromContact
                }
            })
            this.continuingFromContactId.forEach((id) => {
                this.orderedContinuingRelationships[id] = this.continuingKnownPeople.filter(r => r.fromContact.id === id).map(function (r) {
                    return r.toContact
                })
            }
            )
            this.continuingFromContacts = this.continuingFromContacts.filter((n) => n);
        }
    }
}
